import './custom.scss';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import App from './App';
import reportWebVitals from './reportWebVitals';
import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom';
import MainNav from './pages/main-nav';
import ErrorPage from './pages/error';
import Users, { userLoader } from './pages/users';
import AddUser, { userAction } from './pages/add-user';
import Classes, { classLoader } from './pages/classes';
import Learners, { learnerLoader } from './pages/learners';
import Books, { bookLoader } from './pages/books';
import AddClass, { classAction } from './pages/add-class';
import AddLearner, { learnerAction } from './pages/add-learner';
import AddBook, { bookAction } from './pages/add-book';
import Assets, { assetLoader } from './pages/assets';
import AssignBook, { assignBookAction, assignBookLoader } from './pages/assign-book';
import ViewLearner from './pages/view-learner';
import TrackBook, { trackBookAction, trackBookLoader } from './pages/track-book';
import ViewBook from './pages/view-book';
import AssignAsset, { assignAssetAction, assignAssetLoader } from './pages/assign-asset';
import EditBook, { editBookAction, editBookLoader } from './pages/edit-book';
import EditLearner, { editLearnerAction, editLearnerLoader } from './pages/edit-learner';
import RegisterAssetReturn, { registerAssetReturnAction, registerAssetReturnLoader } from './pages/register-asset-return';
import ReportMissingAsset, { reportMissingAssetAction, reportMissingAssetLoader } from './pages/report-missing-asset';
import { Auth0Provider } from '@auth0/auth0-react';
import Login from './components/login';
import Home from './pages/home';
import { getConfig } from './config';
import Logout from './components/logout';
import EditUser, { editUserAction, editUserLoader } from './pages/edit-user';
import EditClass, { editClassAction, editClassLoader } from './pages/edit-class';
import DeleteClass, { deleteClassAction, deleteClassLoader } from './pages/delete-class';
import DeleteLearner, { deleteLearnerAction, deleteLearnerLoader } from './pages/delete-learner';
import DeleteBook, { deleteBookAction, deleteBookLoader } from './pages/delete-book';
import ErrorHandler from './components/error-handler';

const config = getConfig();

const providerConfig = {
  domain: config.domain,
  clientId: config.clientId,
  authorizationParams: {
    redirect_uri: `${window.location.origin}/assets`,
  },
};

const router = createBrowserRouter([
  {
    path: '/',
    element: <MainNav />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <Navigate to='/home' replace /> },
      { path: 'home', element: <Home /> },
      { path: 'login', element: <Login /> },
      { path: 'logout', element: <Logout /> },
      {
        path: 'users',
        element: <Users />,
        loader: userLoader,
        children: [
          {
            path: 'add',
            element: <AddUser />,
            action: userAction,
          },
          {
            path: 'edit/:userId',
            element: <EditUser />,
            loader: editUserLoader,
            action: editUserAction,
          },
        ],
      },
      {
        path: 'classes',
        element: <Classes />,
        loader: classLoader,
        children: [
          {
            path: 'add',
            element: <AddClass />,
            action: classAction,
          },
          {
            path: 'edit/:classId',
            element: <EditClass />,
            loader: editClassLoader,
            action: editClassAction,
          },
          {
            path: 'delete/:classId',
            element: <DeleteClass />,
            loader: deleteClassLoader,
            action: deleteClassAction,
          },
        ],
      },
      {
        path: 'learners',
        element: <Learners />,
        loader: learnerLoader,
        children: [
          {
            path: 'add',
            element: <AddLearner />,
            loader: classLoader,
            action: learnerAction,
          },
          {
            path: 'assign-book/:learnerId',
            element: <AssignBook />,
            loader: assignBookLoader,
            action: assignBookAction,
          },
          {
            path: 'edit/:learnerId',
            element: <EditLearner />,
            loader: editLearnerLoader,
            action: editLearnerAction,
          },
          {
            path: 'delete/:learnerId',
            element: <DeleteLearner />,
            errorElement: <ErrorHandler />,
            loader: deleteLearnerLoader,
            action: deleteLearnerAction,
          },
          {
            path: ':learnerId',
            element: <ViewLearner />,
            children: [
              {
                path: 'assign-book',
                element: <AssignBook />,
                loader: assignBookLoader,
                action: assignBookAction,
              },
            ],
          },
        ],
      },
      {
        path: 'books',
        element: <Books />,
        loader: bookLoader,
        children: [
          {
            path: 'add',
            element: <AddBook />,
            action: bookAction,
          },
          {
            path: 'assign/:bookId',
            element: <TrackBook />,
            loader: trackBookLoader,
            action: trackBookAction,
          },
          {
            path: 'edit/:bookId',
            element: <EditBook />,
            loader: editBookLoader,
            action: editBookAction,
          },
          {
            path: 'delete/:bookId',
            element: <DeleteBook />,
            errorElement: <ErrorHandler />,
            loader: deleteBookLoader,
            action: deleteBookAction,
          },
          {
            path: ':bookId',
            element: <ViewBook />,
            children: [
              {
                path: 'assign',
                element: <TrackBook />,
                loader: trackBookLoader,
                action: trackBookAction,
              },
            ],
          },
        ],
      },
      {
        path: 'assets',
        element: <Assets />,
        loader: assetLoader,
        children: [
          {
            path: ':assetId/assign',
            element: <AssignAsset />,
            loader: assignAssetLoader,
            action: assignAssetAction,
          },
          {
            path: ':assetId/return',
            element: <RegisterAssetReturn />,
            loader: registerAssetReturnLoader,
            action: registerAssetReturnAction,
          },
          {
            path: ':assetId/missing',
            element: <ReportMissingAsset />,
            loader: reportMissingAssetLoader,
            action: reportMissingAssetAction,
          }
        ]
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    {/* <App /> */}
    <Auth0Provider {...providerConfig}>
      <RouterProvider router={router} />
    </Auth0Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { useEffect, useMemo, useRef, useState } from 'react';
import { Col, Row, Table, FormControl, FormLabel, FormSelect, Pagination, Button } from 'react-bootstrap';
import styled from 'styled-components';
import { debounce, get, isEmpty, isString, toLower } from 'lodash';

const StyledOption = styled.option``;
const StyledTableHead = styled.thead``;
const StyledTableBody = styled.tbody``;
const StyledTableRow = styled.tr``;
const StyledColHeading = styled.th``;
const StyledTableCell = styled.td``;
const StyledButton = styled(Button)``;

export interface ColumnAction<T> {
  text: string;
  className?: string;
  onClick: (item: T, event: any) => void;
  showAction?: (item: T) => boolean;
}

export interface Column<T> {
  heading: string;
  type: 'data' | 'actions'
  path?: string;
  actions?: ColumnAction<T>[];
}

interface DataTableProps<T> {
  rows: T[];
  columns: Column<T>[];
}

function DataTable<T>({ rows, columns }: DataTableProps<T>) {
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [filteredRows, setFilteredRows] = useState(rows);

  const numOfPages = useMemo(() => Math.ceil(filteredRows.length / recordsPerPage), [filteredRows.length, recordsPerPage]);

  const mappedColumns = useMemo(() => {
    return columns.map((column, index) => (
      <StyledColHeading key={index}>{column.heading}</StyledColHeading>
    ));
  }, [columns]);

  const mappedRows = useMemo(() => {
    const startIndex = currentPageIndex * recordsPerPage;
    const endIndex = startIndex + recordsPerPage;
    return filteredRows.slice(startIndex, endIndex).map((row, index) => (
      <StyledTableRow key={index}>
        {columns.map((column, idx) => {
          let cell, mappedActions;
          if (column.type === 'actions' && !isEmpty(column.actions)) {
            mappedActions = column.actions!.filter(action => !action.showAction || action.showAction(row)).map((action, idx) => (
              <StyledButton key={idx} variant='link' className={action.className} onClick={event => action.onClick(row, event)}>{action.text}</StyledButton>
            ));
          } else {
            const path = column.path || '';
            cell = String(get(row, path));
          }
          return (
            <StyledTableCell key={idx}>{column.type === 'actions' ? mappedActions : cell}</StyledTableCell>
          );
        })}
      </StyledTableRow>
    ));
  }, [filteredRows, columns, recordsPerPage, currentPageIndex]);

  const searchTextRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (searchTextRef.current) searchTextRef.current.value = '';
    setFilteredRows(rows);
  }, [rows]);

  const handleRecordsPerPageChange = (event: any) => {
    if (event.target) {
      setRecordsPerPage(+event.target.value);
      setCurrentPageIndex(0);
    }
  };

  const nextPage = () => {
    if ((currentPageIndex + 1) < numOfPages) {
      setCurrentPageIndex(currentPageIndex + 1);
    }
  }

  const prevPage = () => {
    if ((currentPageIndex + 1) > 1) {
      setCurrentPageIndex(currentPageIndex - 1);
    }
  }

  const firstPage = () => {
    setCurrentPageIndex(0);
  }

  const lastPage = () => {
    if (numOfPages > 0) setCurrentPageIndex(numOfPages - 1);
  }

  const filterByText = debounce((event: any) => {
    const _searchText = get(event, 'target.value');
    if (!isString(_searchText)) return;

    const _filteredRows = rows.filter(row => {
      for (const column of columns) {
        const path = column.path || '';
        const cell = get(row, path);
        if (toLower(String(cell)).includes(toLower(_searchText))) return true;
      }
      return false;
    });
    setFilteredRows(_filteredRows);
    setCurrentPageIndex(0);
  }, 300);

  return (
    <>
      {rows.length > 0 && (
        <Row className='py-3'>
          <Col>
            <FormLabel>
              Show
              <FormSelect onChange={handleRecordsPerPageChange} defaultValue={recordsPerPage} size='sm' className='w-auto d-inline mx-2'>
                <StyledOption value="10">10</StyledOption>
                <StyledOption value="25">25</StyledOption>
                <StyledOption value="50">50</StyledOption>
                <StyledOption value="100">100</StyledOption>
              </FormSelect>
              records per page
            </FormLabel>
          </Col>
          <Col className="d-flex justify-content-end">
            <FormControl ref={searchTextRef} size='sm' type="text" placeholder="Search" className='w-auto' onChange={filterByText} />
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <Table striped hover responsive>
            <StyledTableHead>
              <StyledTableRow>
                {mappedColumns}
              </StyledTableRow>
            </StyledTableHead>
            <StyledTableBody>
              {mappedRows.length > 0 ? mappedRows : (
                <StyledTableRow>
                  <StyledTableCell colSpan={columns.length} className='text-center'>No record(s) found</StyledTableCell>
                </StyledTableRow>
              )}
            </StyledTableBody>
          </Table>
        </Col>
      </Row>
      {numOfPages > 1 && (
        <Row>
          <Col>
            Page: {(currentPageIndex + 1)} of {numOfPages}
          </Col>
          <Col className="d-flex justify-content-end">
            <Pagination>
              <Pagination.First onClick={firstPage} />
              <Pagination.Prev onClick={prevPage} />
              <Pagination.Next onClick={nextPage} />
              <Pagination.Last onClick={lastPage} />
            </Pagination>
          </Col>
        </Row>
      )}
    </>
  );
}

export default DataTable;

import { Form, Row, Col, Button, Modal } from 'react-bootstrap';
import { ActionFunctionArgs, redirect, useNavigate, useSubmit } from 'react-router-dom';
import styled from 'styled-components';
import { startCase } from 'lodash';
import { addClass } from '../api';
import { Field, Formik, Form as FormikForm } from 'formik';
import { object, string } from 'yup';


const StyledOption = styled.option``;
const StyledButton = styled(Button)`
  background-color: #3498db;
  border-color: #3498db;
  color: #fff;
`;

export async function classAction({ request }: ActionFunctionArgs) {
  const payload = await request.json();
  await addClass(payload);

  return redirect('/classes');
}

let grades = Array.from({ length: 12 }, (value, index) => (index + 1));

const mappedGradeOptions = grades.map((grade, index) => {
  const option = ({ value: grade, label: startCase(String(grade)) });
  return (<StyledOption key={index} value={option.value}>{option.label}</StyledOption>);
});

const initialValues = {
  grade: '',
  class: '',
};

const validationSchema = object({
  grade: string().required('Grade is required'),
  class: string().required('Class is required'),
});

function AddClass() {
  const navigate = useNavigate();
  const submit = useSubmit();

  const handleClose = () => navigate('/classes');

  const handleSubmit = (values: any) => {
    submit(values, { method: "post", encType: 'application/json' });
  };

  return (
    <Modal
      show={true}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {({ touched, errors }) => (
          <Form as={FormikForm} noValidate>
            <Modal.Header closeButton>
              <Modal.Title>Add Class</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group as={Row} className="mb-3" controlId="grade">
                <Form.Label column sm="4">
                  Grade:
                </Form.Label>
                <Col sm="8">
                  <Field
                    as={Form.Select}
                    name='grade'
                    isInvalid={touched.grade && !!errors.grade}
                  >
                    <StyledOption></StyledOption>
                    {mappedGradeOptions}
                  </Field>
                  <Form.Control.Feedback type='invalid'>{errors.grade}</Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="class">
                <Form.Label column sm="4">
                  Class:
                </Form.Label>
                <Col sm="8">
                  <Field
                    as={Form.Control}
                    type='text'
                    name='class'
                    isInvalid={touched.class && !!errors.class}
                  />
                  <Form.Control.Feedback type='invalid'>{errors.class}</Form.Control.Feedback>
                </Col>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="light" onClick={handleClose}>
                Close
              </Button>
              <StyledButton type='submit'>Submit</StyledButton>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

export default AddClass;

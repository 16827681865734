import { get, has, isString } from 'lodash';
import { useState } from 'react';
import { Toast, ToastContainer } from 'react-bootstrap';
import { useRouteError } from 'react-router-dom';

function ErrorHandler() {
  const error = useRouteError();
  console.error(error);

  const [showToast, setShowToast] = useState(true);

  let message = 'Something went wrong';
  if (has(error, 'response.data.message')) message = String(get(error, 'response.data.message'));
  else if (has(error, 'response.data') && isString(get(error, 'response.data'))) message = String(get(error, 'response.data'));
  else if (has(error, 'message')) message = String(get(error, 'message'));

  return (
    <ToastContainer position='bottom-end'>
      <Toast
        style={{ color: 'white' }}
        onClose={() => setShowToast(false)}
        show={showToast}
        delay={10000}
        bg='danger'
        autohide
      >
        <Toast.Body>{message}</Toast.Body>
      </Toast>
    </ToastContainer>
  );
}

export default ErrorHandler;

import { Form, Row, Col, Button, Modal } from 'react-bootstrap';
import { ActionFunctionArgs, redirect, useLoaderData, useNavigate, useSubmit } from 'react-router-dom';
import styled from 'styled-components';
import { Role } from '../types';
import { get, startCase } from 'lodash';
import { fetchUserById, updateUserById } from '../api';
import { Field, Formik, Form as FormikForm } from 'formik';
import { object, string } from 'yup';
import { useMemo } from 'react';
import { User } from './users';


const StyledOption = styled.option``;
const StyledButton = styled(Button)`
  background-color: #3498db;
  border-color: #3498db;
  color: #fff;
`;

export async function editUserLoader({ params }: any) {
  const user = await fetchUserById(get(params, 'userId'));
  return { user };
}

export async function editUserAction({ request }: ActionFunctionArgs) {
  const { userId, values } = await request.json();
  await updateUserById(userId, values);

  return redirect('/users');
}

const mappedRoleOptions = Object.values(Role).map((role, index) => {
  const option = ({ value: role, label: startCase(role) });
  return (<StyledOption key={index} value={option.value}>{option.label}</StyledOption>);
});

const initialValues = {
  email: '',
  person_id: '',
  person_details: {
    first_name: '',
    last_name: ''
  },
  role: '',
};

const validationSchema = object({
  email: string().required('Email is required').email('Invalid email'),
  person_id: string().required('Person is required'),
  person_details: object().shape({
    first_name: string().required('First name is required'),
    last_name: string().required('Last name is required')
  }),
  role: string().required('Role is required'),
});

function EditUser() {
  const { user } = useLoaderData() as { user: User };
  const navigate = useNavigate();
  const submit = useSubmit();

  const _initialValues = useMemo(() => ({
    ...initialValues,
    email: get(user, 'email'),
    person_id: get(user, 'person.id'),
    person_details: {
      first_name: get(user, 'person.first_name'),
      last_name: get(user, 'person.last_name')
    },
    role: get(user, 'role'),
  }), [user]);

  const handleClose = () => navigate('/users');

  const handleSubmit = (values: any) => {
    submit({ values, userId: user.id }, { method: "put", encType: 'application/json' });
  };

  return (
    <Modal
      show={true}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Formik initialValues={_initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {({ values, touched, errors }) => (
          <Form as={FormikForm} noValidate>
            <Modal.Header closeButton>
              <Modal.Title>Edit User</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group as={Row} className="mb-3" controlId="first_name">
                <Form.Label column sm="4">
                  First Name:
                </Form.Label>
                <Col sm="8">
                  <Field
                    as={Form.Control}
                    type='text'
                    name='person_details.first_name'
                    isInvalid={touched.person_details?.first_name && !!errors.person_details?.first_name}
                  />
                  <Form.Control.Feedback type='invalid'>{errors.person_details?.first_name}</Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="last_name">
                <Form.Label column sm="4">
                  Last Name:
                </Form.Label>
                <Col sm="8">
                  <Field
                    as={Form.Control}
                    type='text'
                    name='person_details.last_name'
                    isInvalid={touched.person_details?.last_name && !!errors.person_details?.last_name}
                  />
                  <Form.Control.Feedback type='invalid'>{errors.person_details?.last_name}</Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="email">
                <Form.Label column sm="4">
                  Email:
                </Form.Label>
                <Col sm="8">
                  <Field
                    as={Form.Control}
                    type='text'
                    name='email'
                    isInvalid={touched.email && !!errors.email}
                  />
                  <Form.Control.Feedback type='invalid'>{errors.email}</Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="role">
                <Form.Label column sm="4">
                  Role:
                </Form.Label>
                <Col sm="8">
                  <Field
                    as={Form.Select}
                    name='role'
                    isInvalid={touched.role && !!errors.role}
                  >
                    <StyledOption></StyledOption>
                    {mappedRoleOptions}
                  </Field>
                  <Form.Control.Feedback type='invalid'>{errors.role}</Form.Control.Feedback>
                </Col>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="light" onClick={handleClose}>
                Close
              </Button>
              <StyledButton type='submit'>Submit</StyledButton>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

export default EditUser;

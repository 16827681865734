import axios from 'axios';
import { get, has, startCase } from 'lodash';

const API_BASE = process.env.REACT_APP_API_BASE as string;

export const addUser = async (userDto: any) => {
  const response = await axios.post(`${API_BASE}/users`, userDto);
  if (!has(response, 'data')) throw new Error('Something went wrong');

  return get(response, 'data');
};

export const addClass = async (classDto: any) => {
  const response = await axios.post(`${API_BASE}/classes`, classDto);
  if (!has(response, 'data')) throw new Error('Something went wrong');

  return get(response, 'data');
};

export const addClasses = async (classesDto: any) => {
  const response = await axios.post(`${API_BASE}/classes/batch`, classesDto);
  if (!has(response, 'data')) throw new Error('Something went wrong');

  return get(response, 'data');
};

export const addLearner = async (learnerDto: any) => {
  const response = await axios.post(`${API_BASE}/learners`, learnerDto);
  if (!has(response, 'data')) throw new Error('Something went wrong');

  return get(response, 'data');
};

export const addLearners = async (learnersDto: any) => {
  let newLearners: any[] = [];

  const response = await axios.post(`${API_BASE}/learners/batch`, learnersDto);
  if (!has(response, 'data')) throw new Error('Something went wrong');

  const apiLearners = response.data as any[] || [];
  newLearners = apiLearners.map(learner => {
    const name = `${get(learner, 'person.first_name')} ${get(learner, 'person.last_name')}`;
    const _class = `${get(learner, 'class.grade')} ${get(learner, 'class.class')}`;
    return {
      ...learner,
      name,
      className: _class
    };
  });

  return newLearners;
};

export const addBook = async (bookDto: any) => {
  const response = await axios.post(`${API_BASE}/books`, bookDto);
  if (!has(response, 'data')) throw new Error('Something went wrong');

  return get(response, 'data');
};

export const addBooks = async (booksDto: any) => {
  let newBooks = [];

  const response = await axios.post(`${API_BASE}/books/batch`, booksDto);
  if (!has(response, 'data')) throw new Error('Something went wrong');

  const apiBooks = response.data as any[] || [];
  newBooks = apiBooks.map(book => {
    const units_left = get(book, 'units', 0) - get(book, 'number_of_assets', 0);
    return {
      ...book,
      units_left
    };
  });

  return newBooks;
};

export const addAsset = async (assetDto: any) => {
  const response = await axios.post(`${API_BASE}/assets`, assetDto);
  if (!has(response, 'data')) throw new Error('Something went wrong');

  return get(response, 'data');
};

export const assignAsset = async (assetId: string, assignAssetDto: any) => {
  const response = await axios.patch(`${API_BASE}/assets/${assetId}/assign`, assignAssetDto);
  if (!has(response, 'data')) throw new Error('Something went wrong');

  return get(response, 'data');
};

export const registerAssetReturn = async (assetId: string, assetReturnDto: any) => {
  const response = await axios.patch(`${API_BASE}/assets/${assetId}/register-return`, assetReturnDto);
  if (!has(response, 'data')) throw new Error('Something went wrong');

  return get(response, 'data');
};

export const reportMissingAsset = async (assetId: string, reportMissingAssetDto: any) => {
  const response = await axios.patch(`${API_BASE}/assets/${assetId}/report-missing`, reportMissingAssetDto);
  if (!has(response, 'data')) throw new Error('Something went wrong');

  return get(response, 'data');
};

export const fetchUsers = async (email?: string) => {
  let users: { name: string; designation: string; role: string; }[] = [];

  try {
    const response = await axios.get(`${API_BASE}/users${email ? `?email=${email}` : ''}`);
    const apiUsers = response.data as any[] || [];
    users = apiUsers.map(user => {
      const name = `${get(user, 'person.first_name')} ${get(user, 'person.last_name')}`;
      const designation = get(user, 'person.specified_designation') || get(user, 'person.designation');
      return {
        ...user,
        name,
        designation: startCase(designation),
        roleName: startCase(user.role)
      };
    });
  } catch (error) {
    console.error(error);
  }

  return users;
};

export const fetchClasses = async () => {
  let classes = [];

  try {
    const response = await axios.get(`${API_BASE}/classes`);
    classes = response.data as any[] || [];
  } catch (error) {
    console.error(error);
  }

  return classes;
};

export const fetchLearners = async () => {
  let learners: any[] = [];

  try {
    const response = await axios.get(`${API_BASE}/learners`);
    const apiLearners = response.data as any[] || [];
    learners = apiLearners.map(learner => {
      const name = `${get(learner, 'person.first_name')} ${get(learner, 'person.last_name')}`;
      const _class = `${get(learner, 'class.grade')} ${get(learner, 'class.class')}`;
      return {
        ...learner,
        name,
        className: _class
      };
    });
  } catch (error) {
    console.error(error);
  }

  return learners;
};

export const fetchBooks = async () => {
  let books = [];

  try {
    const response = await axios.get(`${API_BASE}/books`);
    const apiBooks = response.data as any[] || [];
    books = apiBooks.map(book => {
      const units_left = get(book, 'units', 0) - get(book, 'number_of_assets', 0);
      return {
        ...book,
        units_left
      };
    });
  } catch (error) {
    console.error(error);
  }

  return books;
};

export const fetchAssets = async () => {
  let assets: any[] = [];

  try {
    const response = await axios.get(`${API_BASE}/assets`);
    const apiAssets = response.data as any[] || [];
    assets = apiAssets.map(asset => {
      const book = `${get(asset, 'book.title')} by ${get(asset, 'book.author')} (${get(asset, 'book.isbn')})`;
      let assignee = '';
      if (asset.assigned_to_person) {
        assignee = `${get(asset, 'assigned_to_person.first_name')} ${get(asset, 'assigned_to_person.last_name')}`;
      }

      return {
        id: asset.id,
        book,
        assignee,
        status: asset.status,
        condition: asset.condition,
        missing_reason: asset.missing_reason || '',
      };
    });
  } catch (error) {
    console.error(error);
  }

  return assets;
};

export const fetchLearnerById = async (learnerId: string) => {
  let learner: any = null;

  try {
    const response = await axios.get(`${API_BASE}/learners/${learnerId}`);
    const apiLearner = response.data as any;

    const name = `${get(apiLearner, 'person.first_name')} ${get(apiLearner, 'person.last_name')}`;
    const _class = `${get(apiLearner, 'class.grade')} ${get(apiLearner, 'class.class')}`;
    learner = {
      ...apiLearner,
      name,
      className: _class
    };
  } catch (error) {
    console.error(error);
  }

  return learner;
};

export const fetchBookById = async (bookId: string) => {
  let book: any = null;

  try {
    const response = await axios.get(`${API_BASE}/books/${bookId}`);
    book = response.data as any;
  } catch (error) {
    console.error(error);
  }

  return book;
};

export const fetchAssetById = async (assetId: string) => {
  let asset: any = null;

  try {
    const response = await axios.get(`${API_BASE}/assets/${assetId}`);
    const apiAsset = response.data as any;

    let assignee = '';
    if (apiAsset.assigned_to_person) {
      assignee = `${get(apiAsset, 'assigned_to_person.first_name')} ${get(apiAsset, 'assigned_to_person.last_name')}`;
    }

    asset = {
      id: apiAsset.id,
      book: get(apiAsset, 'book.title'),
      assignee,
      status: apiAsset.status,
      condition: apiAsset.condition,
      missing_reason: apiAsset.missing_reason || '',
    };
  } catch (error) {
    console.error(error);
  }

  return asset;
};

export const fetchUserById = async (userId: string) => {
  let user: any = null;

  try {
    const response = await axios.get(`${API_BASE}/users/${userId}`);
    const apiUser = response.data as any;

    const name = `${get(apiUser, 'person.first_name')} ${get(apiUser, 'person.last_name')}`;
    const designation = get(apiUser, 'person.specified_designation') || get(apiUser, 'person.designation');
    user = {
      ...apiUser,
      name,
      designation: startCase(designation),
      roleName: startCase(apiUser.role)
    };
  } catch (error) {
    console.error(error);
  }

  return user;
};

export const fetchClassById = async (classId: string) => {
  let _class: any = null;

  try {
    const response = await axios.get(`${API_BASE}/classes/${classId}`);
    _class = response.data as any;
  } catch (error) {
    console.error(error);
  }

  return _class;
};

export const updateBookById = async (bookId: string, bookDto: any) => {
  let book: any = null;

  try {
    const response = await axios.put(`${API_BASE}/books/${bookId}`, bookDto);
    book = response.data as any;
  } catch (error) {
    console.error(error);
    throw error;
  }

  return book;
};

export const updateLearnerById = async (learnerId: string, learnerDto: any) => {
  let learner: any = null;

  try {
    const response = await axios.put(`${API_BASE}/learners/${learnerId}`, learnerDto);
    learner = response.data as any;
  } catch (error) {
    console.error(error);
    throw error;
  }

  return learner;
};

export const updateUserById = async (userId: string, userDto: any) => {
  let user: any = null;

  try {
    const response = await axios.put(`${API_BASE}/users/${userId}`, userDto);
    user = response.data as any;
  } catch (error) {
    console.error(error);
    throw error;
  }

  return user;
};

export const updateClassById = async (classId: string, classDto: any) => {
  let _class: any = null;

  try {
    const response = await axios.put(`${API_BASE}/classes/${classId}`, classDto);
    _class = response.data as any;
  } catch (error) {
    console.error(error);
    throw error;
  }

  return _class;
};

export const deleteClassById = async (classId: string) => {
  let deletedClass: any = null;

  try {
    const response = await axios.delete(`${API_BASE}/classes/${classId}`);
    deletedClass = response.data as any;
  } catch (error) {
    console.error(error);
    throw error;
  }

  return deletedClass;
};

export const deleteLearnerById = async (learnerId: string) => {
  let deletedLearner: any = null;

  try {
    const response = await axios.delete(`${API_BASE}/learners/${learnerId}`);
    const apiLearner = response.data as any;

    const name = `${get(apiLearner, 'person.first_name')} ${get(apiLearner, 'person.last_name')}`;
    const _class = `${get(apiLearner, 'class.grade')} ${get(apiLearner, 'class.class')}`;

    deletedLearner = {
      ...apiLearner,
      name,
      className: _class
    };
  } catch (error) {
    console.error(error);
    throw error;
  }

  return deletedLearner;
};

export const deleteBookById = async (bookId: string) => {
  let deletedBook: any = null;

  try {
    const response = await axios.delete(`${API_BASE}/books/${bookId}`);
    deletedBook = response.data as any;
  } catch (error) {
    console.error(error);
    throw error;
  }

  return deletedBook;
};

import { Form, Row, Col, Button, Modal } from 'react-bootstrap';
import { ActionFunctionArgs, redirect, useLoaderData, useNavigate, useSubmit } from 'react-router-dom';
import styled from 'styled-components';
import { Condition } from '../types';
import { addAsset, fetchBookById, fetchLearners } from '../api';
import { Field, Formik, Form as FormikForm } from 'formik';
import { object, string } from 'yup';
import { useMemo } from 'react';
import { get, startCase } from 'lodash';


const StyledOption = styled.option``;
const StyledButton = styled(Button)`
  background-color: #3498db;
  border-color: #3498db;
  color: #fff;
`;

export async function trackBookAction({ request }: ActionFunctionArgs) {
  const payload = await request.json();
  await addAsset(get(payload, 'values'));

  return redirect(`/books/${get(payload, 'book.id', '')}`);
}

export async function trackBookLoader({ params }: any) {
  const book = await fetchBookById(get(params, 'bookId'));
  const learners = await fetchLearners();
  return { book, learners };
}

const mappedConditionOptions = Object.values(Condition).map((condition, index) => {
  const option = ({ value: condition, label: startCase(condition) });
  return (<StyledOption key={index} value={option.value}>{option.label}</StyledOption>);
});

const initialValues = {
  book_id: '',
  condition: '',
  assigned_to: ''
};

const validationSchema = object({
  book_id: string().required('Book is required'),
  condition: string().required('Condition is required'),
  assigned_to: string().required('Learner is required'),
});

function TrackBook() {
  const { book, learners } = useLoaderData() as any;
  const navigate = useNavigate();
  const submit = useSubmit();

  const _initialValues = useMemo(() => ({
    ...initialValues,
    book_id: get(book, 'id')
  }), [book]);

  const handleClose = () => navigate(-1);

  const handleSubmit = (values: any) => {
    submit({ values, book }, { method: "post", encType: 'application/json' });
  };

  const mappedLearnerOptions = useMemo(() => {
    return learners.map((learner: any, index: number) => {
      const option = ({ value: get(learner, 'person.id'), label: learner.name });
      return (<StyledOption key={index} value={option.value}>{option.label}</StyledOption>);
    });
  }, [learners]);

  return (
    <Modal
      show={true}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Formik initialValues={_initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {({ touched, errors }) => (
          <Form as={FormikForm} noValidate>
            <Modal.Header closeButton>
              <Modal.Title>Track Book</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group as={Row} className="mb-3" controlId="book_id">
                <Form.Label column sm="4">
                  Book:
                </Form.Label>
                <Col sm="8">
                  <Field
                    as={Form.Select}
                    disabled
                    readOnly
                    name='book_id'
                    isInvalid={touched.book_id && !!errors.book_id}
                  >
                    <StyledOption value={get(book, 'id', '')}>{book.title}</StyledOption>
                  </Field>
                  <Form.Control.Feedback type='invalid'>{errors.book_id as any}</Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="assigned_to">
                <Form.Label column sm="4">
                  Learner:
                </Form.Label>
                <Col sm="8">
                  <Field
                    as={Form.Select}
                    name='assigned_to'
                    isInvalid={touched.assigned_to && !!errors.assigned_to}
                  >
                    <StyledOption></StyledOption>
                    {mappedLearnerOptions}
                  </Field>
                  <Form.Control.Feedback type='invalid'>{errors.assigned_to}</Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="condition">
                <Form.Label column sm="4">
                  Condition:
                </Form.Label>
                <Col sm="8">
                  <Field
                    as={Form.Select}
                    name='condition'
                    isInvalid={touched.condition && !!errors.condition}
                  >
                    <StyledOption></StyledOption>
                    {mappedConditionOptions}
                  </Field>
                  <Form.Control.Feedback type='invalid'>{errors.condition}</Form.Control.Feedback>
                </Col>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="light" onClick={handleClose}>
                Close
              </Button>
              <StyledButton type='submit'>Submit</StyledButton>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

export default TrackBook;

import { Button, Modal } from 'react-bootstrap';
import { ActionFunctionArgs, redirect, useLoaderData, useNavigate, useSubmit } from 'react-router-dom';
import styled from 'styled-components';
import { fetchClassById, deleteClassById } from '../api';
import { get } from 'lodash';
import { Class } from './classes';

const StyledButton = styled(Button)`
  background-color: #3498db;
  border-color: #3498db;
  color: #fff;
`;

export async function deleteClassLoader({ params }: any) {
  const _class = await fetchClassById(get(params, 'classId'));
  return { _class };
}

export async function deleteClassAction({ request }: ActionFunctionArgs) {
  const { classId } = await request.json();
  await deleteClassById(classId);

  return redirect('/classes');
}

function DeleteClass() {
  const { _class } = useLoaderData() as { _class: Class };
  const navigate = useNavigate();
  const submit = useSubmit();

  const handleClose = () => navigate('/classes');

  const handleSubmit = () => {
    submit({ classId: _class.id }, { method: 'delete', encType: 'application/json' });
  };

  return (
    <Modal
      show={true}
      onHide={handleClose}
      backdrop='static'
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Are you sure?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Grade <b>{`${_class.grade} ${_class.class}`}</b> will be permanently removed from the database.</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='light' onClick={handleClose}>Cancel</Button>
        <StyledButton type='submit' onClick={handleSubmit}>Delete</StyledButton>
      </Modal.Footer>
    </Modal>
  );
}

export default DeleteClass;

import { Nav, NavDropdown, Navbar, Image } from 'react-bootstrap';
import logo from "../assets/images/bee.png";
import avatar from "../assets/images/default.jpg";

import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useMemo } from 'react';
import { get } from 'lodash';

const StyledHeader = styled.header`
  margin-bottom: 4.5rem;
`;

const StyledNav = styled(Nav)`
  & .nav-link {
    color: white;
  }
`;

const UserAvatar = () => {
  const { user, isAuthenticated } = useAuth0();
  return (
    <>
      <Image src={isAuthenticated ? user!.picture : avatar} roundedCircle fluid width="30" /> {isAuthenticated ? user!.name : ''}
    </>
  );
}

function Navigation() {
  const { loginWithRedirect, logout, isAuthenticated, user } = useAuth0();

  const isSuperUser = useMemo(() => {
    const roles: any[] = get(user, 'infoblend_roles') || [];
    return roles.includes('super_admin');
  }, [user]);

  return (
    <StyledHeader>
      <Navbar fixed='top' expand='md' className="px-3" style={{ borderTop: '3px solid gold', backgroundColor: '#3498DB' }}>
        <Navbar.Brand as={Link} to='/home' style={{ color: 'white' }}>
          <Image
            src={logo}
            width="35"
            className="d-inline-block"
          />{' '}
          InfoBlend
        </Navbar.Brand>
        <Navbar.Toggle aria-controls='basic-navbar-nav' />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <StyledNav>
            {
              isAuthenticated
              ? (
                <>
                  {isSuperUser && <Nav.Link as={Link} to='/users'>Users</Nav.Link>}
                  <Nav.Link as={Link} to='/classes'>Classes</Nav.Link>
                  <Nav.Link as={Link} to='/learners'>Learners</Nav.Link>
                  <Nav.Link as={Link} to='/books'>Books</Nav.Link>
                  <Nav.Link as={Link} to='/assets'>Assets</Nav.Link>
                  <NavDropdown className='ml-2' title={<UserAvatar />} id="basic-nav-dropdown" align='end'>
                    <NavDropdown.Item onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>Logout</NavDropdown.Item>
                  </NavDropdown>
                </>
              )
              : (<Nav.Link onClick={() => loginWithRedirect()}>Login</Nav.Link>)
            }
          </StyledNav>
        </Navbar.Collapse>
      </Navbar>
    </StyledHeader>
  );
}

export default Navigation;

import { Form, Row, Col, Button, Modal } from 'react-bootstrap';
import { ActionFunctionArgs, redirect, useLoaderData, useNavigate, useSubmit } from 'react-router-dom';
import styled from 'styled-components';
import { fetchBookById, updateBookById } from '../api';
import { Field, Formik, Form as FormikForm } from 'formik';
import { number, object, string } from 'yup';
import { useMemo } from 'react';
import { Book } from './books';
import { get } from 'lodash';


const StyledButton = styled(Button)`
  background-color: #3498db;
  border-color: #3498db;
  color: #fff;
`;

export async function editBookLoader({ params }: any) {
  const book = await fetchBookById(get(params, 'bookId'));
  return { book };
}

export async function editBookAction({ request }: ActionFunctionArgs) {
  const { bookId, values } = await request.json();
  await updateBookById(bookId, values);

  return redirect('/books');
}

const initialValues = {
  title: '',
  author: '',
  isbn: '',
  publication_date: '',
  genre: '',
  units: 0,
};

const validationSchema = object({
  title: string().required('Title is required'),
  author: string().required('Author is required'),
  isbn: string().required('ISBN is required'),
  publication_date: string().optional(),
  genre: string().optional(),
  units: number().required('Units is required'),
});

function EditBook() {
  const { book } = useLoaderData() as { book: Book };
  const navigate = useNavigate();
  const submit = useSubmit();

  const _initialValues = useMemo(() => ({
    ...initialValues,
    title: book.title,
    author: book.author,
    isbn: book.isbn,
    publication_date: book.publication_date,
    genre: book.genre,
    units: book.units
  }), [book]);

  const handleClose = () => navigate('/books');

  const handleSubmit = (values: any) => {
    submit({ values, bookId: book.id }, { method: "put", encType: 'application/json' });
  };

  return (
    <Modal
      show={true}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Formik initialValues={_initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {({ touched, errors }) => (
          <Form as={FormikForm} noValidate>
            <Modal.Header closeButton>
              <Modal.Title>Edit Book</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group as={Row} className="mb-3" controlId="title">
                <Form.Label column sm="4">
                  Title:
                </Form.Label>
                <Col sm="8">
                  <Field
                    as={Form.Control}
                    type='text'
                    name='title'
                    isInvalid={touched.title && !!errors.title}
                  />
                  <Form.Control.Feedback type='invalid'>{errors.title}</Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="author">
                <Form.Label column sm="4">
                  Author:
                </Form.Label>
                <Col sm="8">
                  <Field
                    as={Form.Control}
                    type='text'
                    name='author'
                    isInvalid={touched.author && !!errors.author}
                  />
                  <Form.Control.Feedback type='invalid'>{errors.author}</Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="isbn">
                <Form.Label column sm="4">
                  ISBN:
                </Form.Label>
                <Col sm="8">
                  <Field
                    as={Form.Control}
                    type='text'
                    name='isbn'
                    isInvalid={touched.isbn && !!errors.isbn}
                  />
                  <Form.Control.Feedback type='invalid'>{errors.isbn}</Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="publication_date">
                <Form.Label column sm="4">
                  Publication Date:
                </Form.Label>
                <Col sm="8">
                  <Field
                    as={Form.Control}
                    type='date'
                    name='publication_date'
                    isInvalid={touched.publication_date && !!errors.publication_date}
                  />
                  <Form.Control.Feedback type='invalid'>{errors.publication_date}</Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="genre">
                <Form.Label column sm="4">
                  Genre:
                </Form.Label>
                <Col sm="8">
                  <Field
                    as={Form.Control}
                    type='text'
                    name='genre'
                    isInvalid={touched.genre && !!errors.genre}
                  />
                  <Form.Control.Feedback type='invalid'>{errors.genre}</Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="units">
                <Form.Label column sm="4">
                  Units:
                </Form.Label>
                <Col sm="8">
                  <Field
                    as={Form.Control}
                    type='number'
                    name='units'
                    isInvalid={touched.units && !!errors.units}
                  />
                  <Form.Control.Feedback type='invalid'>{errors.units}</Form.Control.Feedback>
                </Col>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="light" onClick={handleClose}>
                Close
              </Button>
              <StyledButton type='submit'>Submit</StyledButton>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

export default EditBook;

export enum Designation {
  PRINCIPAL = 'principal',
  VICE_PRINCIPAL = 'vice_principal',
  TEACHER = 'teacher',
  LEARNER = 'learner',
  OTHER = 'other',
}

export enum Role {
  ADMIN = 'admin',
  USER = 'user',
}

export enum Grade {
  ONE = 1,
  TWO = 2,
  THREE = 3,
  FOUR = 4,
  FIVE = 5,
  SIX = 6,
  SEVEN = 7,
  EIGHT = 8,
  NINE = 9,
  TEN = 10,
  ELEVEN = 11,
  TWELVE = 12,
}

export enum Status {
  ASSIGNED = 'assigned',
  UNASSIGNED = 'unassigned',
  MISSING = 'missing',
}

export enum Condition {
  NEW = 'new',
  GOOD = 'good',
  POOR = 'poor',
}

export interface Option<T> {
  value: string;
  label: T
}

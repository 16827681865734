import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { fetchUsers } from '../api';
import DataTable, { Column, ColumnAction } from '../components/data-table';
import PageHeading from '../components/page-heading';
import { Outlet, useLoaderData, useNavigate } from 'react-router-dom';
import { useEffect, useMemo } from 'react';
import { get } from 'lodash';

export async function userLoader() {
  const users = await fetchUsers();
  return { users };
}

export interface User {
  id: string;
  name: string;
  email: string;
  designation: string;
  role: string;
  roleName: string;
}

const columns: Column<User>[] = [
  { heading: 'Name', type: 'data', path: 'name' },
  { heading: 'Email', type: 'data', path: 'email' },
  { heading: 'Designation', type: 'data', path: 'designation' },
  { heading: 'Role', type: 'data', path: 'roleName' }
];

function Users() {
  const { user } = useAuth0();
  const { users } = useLoaderData() as { users: User[] };
  const navigate = useNavigate();

  const isSuperUser = useMemo(() => {
    const roles: any[] = get(user, 'infoblend_roles') || [];
    return roles.includes('super_admin');
  }, [user]);

  const filteredUsers = useMemo(() => {
    return users.filter(user => isSuperUser ? true : user.role !== 'super_admin');
  }, [isSuperUser, users]);

  const columnsWithActions: Column<User>[] = useMemo(() => {
    const actions: ColumnAction<User>[] = [
      {
        text: 'Edit',
        onClick(item, event) {
          console.log(item);
          navigate(`/users/edit/${item.id}`);
        },
        showAction(item) {
          return isSuperUser;
        },
      }
    ];

    return [...columns.slice(), { heading: 'Actions', type: 'actions', actions }];
  }, [isSuperUser, navigate]);

  useEffect(() => {
    if (!isSuperUser) navigate('/home');
  }, [isSuperUser, navigate]);

  const handleAddUserClick = () => navigate('/users/add');

  const actionButton = isSuperUser ? { text: 'Add New User', onClick: handleAddUserClick } : undefined;

  return (
    <>
      <PageHeading heading='Users' actionButton={actionButton} />
      <DataTable<User> rows={filteredUsers || []} columns={columnsWithActions} />
      <Outlet />
    </>
  );
}

export default withAuthenticationRequired(Users);

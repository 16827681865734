import { Form, Row, Col, Button, Modal } from 'react-bootstrap';
import { ActionFunctionArgs, redirect, useLoaderData, useNavigate, useSubmit } from 'react-router-dom';
import styled from 'styled-components';
import { Designation } from '../types';
import { addLearner } from '../api';
import { Field, Formik, Form as FormikForm } from 'formik';
import { object, string } from 'yup';
import { Key, useMemo } from 'react';


const StyledOption = styled.option``;
const StyledButton = styled(Button)`
  background-color: #3498db;
  border-color: #3498db;
  color: #fff;
`;

export async function learnerAction({ request }: ActionFunctionArgs) {
  const payload = await request.json();
  await addLearner(payload);

  return redirect('/learners');
}

const initialValues = {
  person_details: {
    first_name: '',
    last_name: '',
    designation: Designation.LEARNER,
    specified_designation: '',
  },
  class_id: '',
};

const validationSchema = object({
  person_details: object().shape({
    first_name: string().required('First name is required'),
    last_name: string().required('Last name is required'),
    designation: string().required('Designation is required'),
    specified_designation: string().optional().when('designation', {
      is: Designation.OTHER,
      then: () => string().required('Please specify designation'),
      otherwise: schema => schema,
    }),
  }),
  class_id: string().required('Class is required'),
});

function AddLearner() {
  const { classes } = useLoaderData() as any;
  const navigate = useNavigate();
  const submit = useSubmit();

  const handleClose = () => navigate('/learners');

  const handleSubmit = (values: any) => {
    submit(values, { method: "post", encType: 'application/json' });
  };

  const mappedClassOptions = useMemo(() => {
    return classes.map((_class: { id: string; grade: string; class: string; }, index: Key | null | undefined) => {
      const option = ({ value: _class.id, label: `${_class.grade} ${_class.class}` });
      return (<StyledOption key={index} value={option.value}>{option.label}</StyledOption>);
    });
  }, [classes]);

  return (
    <Modal
      show={true}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {({ touched, errors }) => (
          <Form as={FormikForm} noValidate>
            <Modal.Header closeButton>
              <Modal.Title>Add Learner</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group as={Row} className="mb-3" controlId="first_name">
                <Form.Label column sm="4">
                  First Name:
                </Form.Label>
                <Col sm="8">
                  <Field
                    as={Form.Control}
                    type='text'
                    name='person_details.first_name'
                    isInvalid={touched.person_details?.first_name && !!errors.person_details?.first_name}
                  />
                  <Form.Control.Feedback type='invalid'>{errors.person_details?.first_name}</Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="last_name">
                <Form.Label column sm="4">
                  Last Name:
                </Form.Label>
                <Col sm="8">
                  <Field
                    as={Form.Control}
                    type='text'
                    name='person_details.last_name'
                    isInvalid={touched.person_details?.last_name && !!errors.person_details?.last_name}
                  />
                  <Form.Control.Feedback type='invalid'>{errors.person_details?.last_name}</Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="class_id">
                <Form.Label column sm="4">
                  Class:
                </Form.Label>
                <Col sm="8">
                  <Field
                    as={Form.Select}
                    name='class_id'
                    isInvalid={touched.class_id && !!errors.class_id}
                  >
                    <StyledOption></StyledOption>
                    {mappedClassOptions}
                  </Field>
                  <Form.Control.Feedback type='invalid'>{errors.class_id}</Form.Control.Feedback>
                </Col>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="light" onClick={handleClose}>
                Close
              </Button>
              <StyledButton type='submit'>Submit</StyledButton>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

export default AddLearner;

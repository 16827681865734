import { useMemo } from 'react';
import { fetchAssets } from '../api';
import DataTable, { Column, ColumnAction } from '../components/data-table';
import PageHeading from '../components/page-heading';
import { Outlet, useLoaderData, useNavigate } from 'react-router-dom';
import { Status } from '../types';
import { withAuthenticationRequired } from '@auth0/auth0-react';

export async function assetLoader() {
  const assets = await fetchAssets();
  return { assets };
}

export interface Asset {
  id: string;
  book: string;
  assignee: string;
  status: string;
  condition: string;
  missing_reason?: string;
}

const columns: Column<Asset>[] = [
  { heading: 'Tracking ID', type: 'data', path: 'id' },
  { heading: 'Book', type: 'data', path: 'book' },
  { heading: 'Assigned To', type: 'data', path: 'assignee' },
  { heading: 'Status', type: 'data', path: 'status' },
  { heading: 'Condition', type: 'data', path: 'condition' },
  { heading: 'Note', type: 'data', path: 'missing_reason' },
];

function Assets() {
  const { assets } = useLoaderData() as any;
  const navigate = useNavigate();

  const columnsWithActions: Column<Asset>[] = useMemo(() => {
    const actions: ColumnAction<Asset>[] = [
      {
        text: 'Assign',
        onClick(item, event) {
          navigate(`/assets/${item.id}/assign`);
        },
        showAction(item) {
          return item.status === Status.UNASSIGNED;
        }
      },
      {
        text: 'Return',
        onClick(item, event) {
          navigate(`/assets/${item.id}/return`);
        },
        showAction(item) {
          return item.status === Status.ASSIGNED;
        }
      },
      {
        text: 'Missing',
        onClick(item, event) {
          navigate(`/assets/${item.id}/missing`);
        },
        showAction(item) {
          return item.status !== Status.MISSING;
        }
      }
    ];

    return [...columns.slice(), { heading: 'Actions', type: 'actions', actions }];
  }, [navigate]);

  return (
    <>
      <PageHeading heading='Assets' />
      <DataTable<Asset> rows={assets || []} columns={columnsWithActions} />
      <Outlet />
    </>
  );
}

export default withAuthenticationRequired(Assets);

import { Button, Modal } from 'react-bootstrap';
import { ActionFunctionArgs, redirect, useLoaderData, useNavigate, useSubmit } from 'react-router-dom';
import styled from 'styled-components';
import { fetchBookById, deleteBookById } from '../api';
import { get } from 'lodash';
import { Book } from './books';

const StyledButton = styled(Button)`
  background-color: #3498db;
  border-color: #3498db;
  color: #fff;
`;

export async function deleteBookLoader({ params }: any) {
  const book = await fetchBookById(get(params, 'bookId'));
  return { book };
}

export async function deleteBookAction({ request }: ActionFunctionArgs) {
  const { bookId } = await request.json();
  await deleteBookById(bookId);

  return redirect('/books');
}

function DeleteBook() {
  const { book } = useLoaderData() as { book: Book };
  const navigate = useNavigate();
  const submit = useSubmit();

  const handleClose = () => navigate('/books');

  const handleSubmit = () => {
    submit({ bookId: book.id }, { method: 'delete', encType: 'application/json' });
  };

  return (
    <Modal
      show={true}
      onHide={handleClose}
      backdrop='static'
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Are you sure?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p><b>{book.title}</b> by <b>{book.author}</b> will be permanently removed from the database.</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='light' onClick={handleClose}>Cancel</Button>
        <StyledButton type='submit' onClick={handleSubmit}>Delete</StyledButton>
      </Modal.Footer>
    </Modal>
  );
}

export default DeleteBook;

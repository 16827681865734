import { Form, Row, Col, Button, Modal } from 'react-bootstrap';
import { ActionFunctionArgs, redirect, useLoaderData, useNavigate, useSubmit } from 'react-router-dom';
import styled from 'styled-components';
import { fetchAssetById, reportMissingAsset } from '../api';
import { Field, Formik, Form as FormikForm } from 'formik';
import { object, string } from 'yup';
import { get } from 'lodash';
import { Asset } from './assets';

const StyledButton = styled(Button)`
  background-color: #3498db;
  border-color: #3498db;
  color: #fff;
`;

export async function reportMissingAssetAction({ request }: ActionFunctionArgs) {
  const { assetId, values } = await request.json();
  await reportMissingAsset(assetId, values);

  return redirect(`/assets`);
}

export async function reportMissingAssetLoader({ params }: any) {
  const asset = await fetchAssetById(get(params, 'assetId'));
  return { asset };
}

const initialValues = {
  reason: ''
};

const validationSchema = object({
  reason: string().required('Reason is required')
});

function ReportMissingAsset() {
  const { asset } = useLoaderData() as { asset: Asset };
  const navigate = useNavigate();
  const submit = useSubmit();

  const handleClose = () => navigate(-1);

  const handleSubmit = (values: any) => {
    submit({ values, assetId: asset.id }, { method: "patch", encType: 'application/json' });
  };

  return (
    <Modal
      show={true}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {({ touched, errors }) => (
          <Form as={FormikForm} noValidate>
            <Modal.Header closeButton>
              <Modal.Title>Report Missing Asset</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group as={Row} className="mb-3" controlId="reason">
                <Form.Label column sm="4">
                  Reason:
                </Form.Label>
                <Col sm="8">
                  <Field
                    as={Form.Control}
                    type='text'
                    name='reason'
                    isInvalid={touched.reason && !!errors.reason}
                  />
                  <Form.Control.Feedback type='invalid'>{errors.reason}</Form.Control.Feedback>
                </Col>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="light" onClick={handleClose}>
                Close
              </Button>
              <StyledButton type='submit'>Submit</StyledButton>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

export default ReportMissingAsset;

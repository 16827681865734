import { Button, Modal } from 'react-bootstrap';
import { ActionFunctionArgs, redirect, useLoaderData, useNavigate, useSubmit } from 'react-router-dom';
import styled from 'styled-components';
import { fetchLearnerById, deleteLearnerById } from '../api';
import { get } from 'lodash';
import { Learner } from './learners';

const StyledButton = styled(Button)`
  background-color: #3498db;
  border-color: #3498db;
  color: #fff;
`;

export async function deleteLearnerLoader({ params }: any) {
  const learner = await fetchLearnerById(get(params, 'learnerId'));
  return { learner };
}

export async function deleteLearnerAction({ request }: ActionFunctionArgs) {
  const { learnerId } = await request.json();
  await deleteLearnerById(learnerId);

  return redirect('/learners');
}

function DeleteLearner() {
  const { learner } = useLoaderData() as { learner: Learner };
  const navigate = useNavigate();
  const submit = useSubmit();

  const handleClose = () => navigate('/learners');

  const handleSubmit = () => {
    submit({ learnerId: learner.id }, { method: 'delete', encType: 'application/json' });
  };

  return (
    <Modal
      show={true}
      onHide={handleClose}
      backdrop='static'
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Are you sure?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p><b>{learner.name}</b> from grade <b>{learner.className}</b> will be permanently removed from the database.</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='light' onClick={handleClose}>Cancel</Button>
        <StyledButton type='submit' onClick={handleSubmit}>Delete</StyledButton>
      </Modal.Footer>
    </Modal>
  );
}

export default DeleteLearner;

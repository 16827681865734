import { withAuthenticationRequired } from '@auth0/auth0-react';
import { addClasses, fetchClasses } from '../api';
import DataTable, { Column, ColumnAction } from '../components/data-table';
import PageHeading from '../components/page-heading';
import { Outlet, useLoaderData, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Button, Spinner, Toast, ToastContainer } from 'react-bootstrap';
import UploadIcon from '../components/icons/upload-icon';
import { SyntheticEvent, useMemo, useRef, useState } from 'react';
import { get, isEmpty } from 'lodash';
import Papa from 'papaparse';
import DownloadIcon from '../components/icons/download-icon';
import { isNumeric } from '../utils/general';

export async function classLoader() {
  const classes = await fetchClasses();
  return { classes };
}

export interface Class {
  id: string;
  grade: string;
  class: string;
}

const columns: Column<Class>[] = [
  { heading: 'Grade', type: 'data', path: 'grade' },
  { heading: 'Class', type: 'data', path: 'class' },
];

function Classes() {
  const { classes } = useLoaderData() as any;
  const navigate = useNavigate();

  const [isSavingClasses, setIsSavingClasses] = useState(false);
  const [addedClasses, setAddedClasses] = useState<any[]>([]);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastBg, setToastBg] = useState('dark');

  const fileInputRef = useRef<HTMLInputElement>();

  const columnsWithActions: Column<Class>[] = useMemo(() => {
    const actions: ColumnAction<Class>[] = [
      {
        text: 'Edit',
        onClick(item, event) {
          navigate(`/classes/edit/${item.id}`);
        },
      },
      {
        text: 'Delete',
        className: 'text-danger',
        onClick(item, event) {
          navigate(`/classes/delete/${item.id}`);
        },
      },
    ];

    return [...columns.slice(), { heading: 'Actions', type: 'actions', actions }];
  }, [navigate]);

  const handleAddClassClick = () => navigate('/classes/add');

  const handleFileChange = (event: SyntheticEvent<HTMLInputElement>) => {
    const file = get(event, 'currentTarget.files[0]');
    if (file) {
      setIsSavingClasses(true);

      Papa.parse<any>(file, {
        header: true,
        skipEmptyLines: true,
        complete: async function (results) {
          try {
            if (!isEmpty(results.errors)) throw results.errors;

            const classesPayload = results.data.map((row) => {
              const mappedRow: Record<string, any> = {};

              const keys = Object.keys(row);
              for (const key of keys) {
                const lowerCasedKey = key.toLowerCase();
                if (!['grade', 'class'].includes(lowerCasedKey)) throw new Error('Unknown key/heading');
                if (lowerCasedKey === 'grade') {
                  if (!isNumeric(row[key])) throw new Error('Grade should be a number');
                  else {
                    const grade = Number(row[key]);
                    if (grade < 1 || grade > 12) throw new Error('Grade should be between 1 and 12');
                  }
                }

                mappedRow[lowerCasedKey] = row[key];
              }

              return mappedRow;
            });

            const newClasses: any[] = await addClasses({ classes: classesPayload });
            setAddedClasses(currentClasses => [...currentClasses, ...newClasses]);

            fileInputRef.current!.value = '';
          } catch (errors) {
            setToastMessage(String(errors));
            setToastBg('danger');
            setShowToast(true);
            console.error(errors);
          } finally {
            setIsSavingClasses(false);
          }
        },
      });
    }
  }

  const handleUploadButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleDownloadButtonClick = () => {
    const link = document.createElement('a');
    link.download = 'classes.csv';
    link.target = '_blank';
    link.href = './csv/classes.csv';
    link.click();
  };

  return (
    <>
      <PageHeading heading='Classes' actionButton={{ text: 'Add New Class', onClick: handleAddClassClick }} />
      <DataTable<Class> rows={[...(classes || []), ...addedClasses]} columns={columnsWithActions} />

      <StyledButton
        disabled={isSavingClasses}
        onClick={!isSavingClasses ? handleUploadButtonClick : undefined}
      >
        {
          isSavingClasses
          ? (
            <>
              <Spinner animation='border' role='status'>
                <span className='visually-hidden'>Loading...</span>
              </Spinner>&nbsp; Saving...
            </>
          )
          : (
            <><UploadIcon />&nbsp; Load Classes</>
          )
        }
      </StyledButton>
      <StyledInput ref={fileInputRef as any} type='file' accept='.csv' onChange={handleFileChange} />
      <StyledButton onClick={handleDownloadButtonClick}><DownloadIcon />&nbsp; Download CSV Template</StyledButton>

      <ToastContainer position='bottom-end'>
        <Toast
          style={{ color: 'white' }}
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={10000}
          bg={toastBg}
          autohide
        >
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
      </ToastContainer>

      <Outlet />
    </>
  );
}

export default withAuthenticationRequired(Classes);

const StyledButton = styled(Button)`
  background-color: #3498db;
  border-color: #3498db;
  color: #fff;
  margin-right: 10px;
`;

const StyledInput = styled.input`
  display: none;
`;

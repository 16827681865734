import { Form, Row, Col, Button, Modal } from 'react-bootstrap';
import { ActionFunctionArgs, redirect, useNavigate, useSubmit } from 'react-router-dom';
import styled from 'styled-components';
import { Designation, Role } from '../types';
import { startCase } from 'lodash';
import { addUser } from '../api';
import { Field, Formik, Form as FormikForm } from 'formik';
import { object, string } from 'yup';


const StyledOption = styled.option``;
const StyledButton = styled(Button)`
  background-color: #3498db;
  border-color: #3498db;
  color: #fff;
`;

export async function userAction({ request }: ActionFunctionArgs) {
  const payload = await request.json();
  await addUser(payload);

  return redirect('/users');
}

const mappedDesignationOptions = Object.values(Designation).map((designation, index) => {
  const option = ({ value: designation, label: startCase(designation) });
  return (<StyledOption key={index} value={option.value}>{option.label}</StyledOption>);
});

const mappedRoleOptions = Object.values(Role).map((role, index) => {
  const option = ({ value: role, label: startCase(role) });
  return (<StyledOption key={index} value={option.value}>{option.label}</StyledOption>);
});

const initialValues = {
  email: '',
  person_details: {
    first_name: '',
    last_name: '',
    designation: '',
    specified_designation: '',
  },
  role: '',
};

const validationSchema = object({
  email: string().required('Email is required').email('Invalid email'),
  person_details: object().shape({
    first_name: string().required('First name is required'),
    last_name: string().required('Last name is required'),
    designation: string().required('Designation is required'),
    specified_designation: string().optional().when('designation', {
      is: Designation.OTHER,
      then: () => string().required('Please specify designation'),
      otherwise: schema => schema,
    }),
  }),
  role: string().required('Role is required'),
});

function AddUser() {
  const navigate = useNavigate();
  const submit = useSubmit();

  const handleClose = () => navigate('/users');

  const handleSubmit = (values: any) => {
    submit(values, { method: "post", encType: 'application/json' });
  };

  return (
    <Modal
      show={true}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {({ values, touched, errors }) => (
          <Form as={FormikForm} noValidate>
            <Modal.Header closeButton>
              <Modal.Title>Add User</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group as={Row} className="mb-3" controlId="first_name">
                <Form.Label column sm="4">
                  First Name:
                </Form.Label>
                <Col sm="8">
                  <Field
                    as={Form.Control}
                    type='text'
                    name='person_details.first_name'
                    isInvalid={touched.person_details?.first_name && !!errors.person_details?.first_name}
                  />
                  <Form.Control.Feedback type='invalid'>{errors.person_details?.first_name}</Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="last_name">
                <Form.Label column sm="4">
                  Last Name:
                </Form.Label>
                <Col sm="8">
                  <Field
                    as={Form.Control}
                    type='text'
                    name='person_details.last_name'
                    isInvalid={touched.person_details?.last_name && !!errors.person_details?.last_name}
                  />
                  <Form.Control.Feedback type='invalid'>{errors.person_details?.last_name}</Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="email">
                <Form.Label column sm="4">
                  Email:
                </Form.Label>
                <Col sm="8">
                  <Field
                    as={Form.Control}
                    type='text'
                    name='email'
                    isInvalid={touched.email && !!errors.email}
                  />
                  <Form.Control.Feedback type='invalid'>{errors.email}</Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="designation">
                <Form.Label column sm="4">
                  Designation:
                </Form.Label>
                <Col sm="8">
                  <Field
                    as={Form.Select}
                    name='person_details.designation'
                    isInvalid={touched.person_details?.designation && !!errors.person_details?.designation}
                  >
                    <StyledOption></StyledOption>
                    {mappedDesignationOptions}
                  </Field>
                  <Form.Control.Feedback type='invalid'>{errors.person_details?.designation}</Form.Control.Feedback>
                </Col>
              </Form.Group>
              {values.person_details.designation === Designation.OTHER && (
                <Form.Group as={Row} className="mb-3" controlId="specified_designation">
                  <Form.Label column sm="4">
                    Specify Designation:
                  </Form.Label>
                  <Col sm="8">
                    <Field
                      as={Form.Control}
                      type='text'
                      name='person_details.specified_designation'
                      isInvalid={touched.person_details?.specified_designation && !!errors.person_details?.specified_designation}
                    />
                    <Form.Control.Feedback type='invalid'>{errors.person_details?.specified_designation}</Form.Control.Feedback>
                  </Col>
                </Form.Group>
              )}
              <Form.Group as={Row} className="mb-3" controlId="role">
                <Form.Label column sm="4">
                  Role:
                </Form.Label>
                <Col sm="8">
                  <Field
                    as={Form.Select}
                    name='role'
                    isInvalid={touched.role && !!errors.role}
                  >
                    <StyledOption></StyledOption>
                    {mappedRoleOptions}
                  </Field>
                  <Form.Control.Feedback type='invalid'>{errors.role}</Form.Control.Feedback>
                </Col>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="light" onClick={handleClose}>
                Close
              </Button>
              <StyledButton type='submit'>Submit</StyledButton>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

export default AddUser;

import { SyntheticEvent, useMemo, useRef, useState } from 'react';
import { addLearners, fetchLearners } from '../api';
import DataTable, { Column, ColumnAction } from '../components/data-table';
import PageHeading from '../components/page-heading';
import { Outlet, useLoaderData, useNavigate } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import styled from 'styled-components';
import { Button, Spinner, Toast, ToastContainer } from 'react-bootstrap';
import UploadIcon from '../components/icons/upload-icon';
import DownloadIcon from '../components/icons/download-icon';
import { get, isEmpty } from 'lodash';
import Papa from 'papaparse';
import { isNumeric } from '../utils/general';

export async function learnerLoader() {
  const learners = await fetchLearners();
  return { learners };
}

export interface Learner {
  id: string;
  name: string;
  className: string;
}

const columns: Column<Learner>[] = [
  { heading: 'Name', type: 'data', path: 'name' },
  { heading: 'Class', type: 'data', path: 'className' },
];

function Learners() {
  const { learners } = useLoaderData() as any;
  const navigate = useNavigate();

  const [isSavingLearners, setIsSavingLearners] = useState(false);
  const [addedLearners, setAddedLearners] = useState<any[]>([]);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastBg, setToastBg] = useState('dark');

  const fileInputRef = useRef<HTMLInputElement>();

  const columnsWithActions: Column<Learner>[] = useMemo(() => {
    const actions: ColumnAction<Learner>[] = [
      {
        text: 'Edit',
        onClick(item, event) {
          navigate(`/learners/edit/${item.id}`);
        }
      },
      {
        text: 'Delete',
        className: 'text-danger',
        onClick(item, event) {
          navigate(`/learners/delete/${item.id}`);
        }
      },
      {
        text: 'Assign Book',
        onClick(item, event) {
          navigate(`/learners/assign-book/${item.id}`);
        },
        showAction(item) {
          return false;
        },
      }
    ];

    return [...columns.slice(), { heading: 'Actions', type: 'actions', actions }];
  }, [navigate]);

  const handleAddLearnerClick = () => navigate('/learners/add');

  const handleFileChange = (event: SyntheticEvent<HTMLInputElement>) => {
    const file = get(event, 'currentTarget.files[0]');
    if (file) {
      setIsSavingLearners(true);

      Papa.parse<any>(file, {
        header: true,
        skipEmptyLines: true,
        complete: async function (results) {
          try {
            if (!isEmpty(results.errors)) throw results.errors;

            const learnersPayload = results.data.map((row) => {
              const mappedRow: Record<string, any> = {
                person_details: { designation: 'learner' },
                class_details: {}
              };

              const keys = Object.keys(row);
              for (const key of keys) {
                const lowerCasedKey = key.toLowerCase();
                if (lowerCasedKey === 'grade') {
                  if (!isNumeric(row[key])) throw new Error('Grade should be a number');
                  else {
                    const grade = Number(row[key]);
                    if (grade < 1 || grade > 12) throw new Error('Grade should be between 1 and 12');
                  }
                }

                switch (lowerCasedKey) {
                  case 'first_name':
                  case 'last_name':
                    mappedRow['person_details'][lowerCasedKey] = row[key];
                    break;

                  case 'grade':
                  case 'class':
                    mappedRow['class_details'][lowerCasedKey] = row[key];
                    break;

                  default:
                    throw new Error('Unknown key/heading');
                }
              }

              return mappedRow;
            });

            const newLearners: any[] = await addLearners({ learners: learnersPayload });
            setAddedLearners(currentLearners => [...currentLearners, ...newLearners]);

            fileInputRef.current!.value = '';
          } catch (errors) {
            setToastMessage(String(errors));
            setToastBg('danger');
            setShowToast(true);
            console.error(errors);
          } finally {
            setIsSavingLearners(false);
          }
        },
      });
    }
  }

  const handleUploadButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleDownloadButtonClick = () => {
    const link = document.createElement('a');
    link.download = 'learners.csv';
    link.target = '_blank';
    link.href = './csv/learners.csv';
    link.click();
  };

  return (
    <>
      <PageHeading heading='Learners' actionButton={{ text: 'Add New Learner', onClick: handleAddLearnerClick }} />
      <DataTable<Learner> rows={[...(learners || []), ...addedLearners]} columns={columnsWithActions} />

      <StyledButton
        disabled={isSavingLearners}
        onClick={!isSavingLearners ? handleUploadButtonClick : undefined}
      >
        {
          isSavingLearners
          ? (
            <>
              <Spinner animation='border' role='status'>
                <span className='visually-hidden'>Loading...</span>
              </Spinner>&nbsp; Saving...
            </>
          )
          : (
            <><UploadIcon />&nbsp; Load Learners</>
          )
        }
      </StyledButton>
      <StyledInput ref={fileInputRef as any} type='file' accept='.csv' onChange={handleFileChange} />
      <StyledButton onClick={handleDownloadButtonClick}><DownloadIcon />&nbsp; Download CSV Template</StyledButton>

      <ToastContainer position='bottom-end'>
        <Toast
          style={{ color: 'white' }}
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={10000}
          bg={toastBg}
          autohide
        >
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
      </ToastContainer>

      <Outlet />
    </>
  );
}

export default withAuthenticationRequired(Learners);

const StyledButton = styled(Button)`
  background-color: #3498db;
  border-color: #3498db;
  color: #fff;
  margin-right: 10px;
`;

const StyledInput = styled.input`
  display: none;
`;

import { Outlet } from 'react-router-dom';
import Navigation from '../components/navigation';
import styled from 'styled-components';
import { Container } from 'react-bootstrap';

const StyledMain = styled.main``;
const StyledFooter = styled.footer``;
const StyledParagraph = styled.p``;

const currentYear = () => new Date().getFullYear();

function MainNav() { // layout page
  return (
    <>
      <Navigation /> {/*Header*/}
      <StyledMain className='flex-shrink-0 pt-3'>
        <Container fluid>
          <Outlet />
        </Container>
      </StyledMain>
      <StyledFooter className='footer mt-auto py-3'>
        <Container fluid>
          <StyledParagraph className="text-muted text-center mb-0">
            Copyright &copy; {currentYear()} Blueberry Bee (Pty) Ltd. All rights reserved.
          </StyledParagraph>
        </Container>
      </StyledFooter>
    </>
  );
}

export default MainNav;

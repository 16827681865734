import { Button, Col, Row } from 'react-bootstrap';
import styled from 'styled-components';

const StyledHeading3 = styled.h3``;
const StyledButton = styled(Button)`
  background-color: #3498db;
  border-color: #3498db;
  color: #fff;
`;

interface PageHeadingProps {
  heading: string;
  actionButton?: {
    text: string;
    onClick: (event: any) => void;
  }
}

function PageHeading({ heading, actionButton }: PageHeadingProps) {
  return (
    <Row>
      <Col>
        <StyledHeading3>{heading}</StyledHeading3>
      </Col>
      {actionButton && (
        <Col className="d-flex justify-content-end">
          <StyledButton onClick={actionButton!.onClick}>{actionButton!.text}</StyledButton>
        </Col>
      )}
    </Row>
  );
}

export default PageHeading;

import { useMemo } from 'react';
import { addBooks, fetchBooks } from '../api';
import DataTable, { Column, ColumnAction } from '../components/data-table';
import PageHeading from '../components/page-heading';
import { Outlet, useLoaderData, useNavigate } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import styled from 'styled-components';
import { Button, Spinner, Toast, ToastContainer } from 'react-bootstrap';
import UploadIcon from '../components/icons/upload-icon';
import { SyntheticEvent, useRef, useState } from 'react';
import { capitalize, get, isEmpty } from 'lodash';
import Papa from 'papaparse';
import DownloadIcon from '../components/icons/download-icon';
import { isNumeric } from '../utils/general';

export async function bookLoader() {
  const books = await fetchBooks();
  return { books };
}

export interface Book {
  id: string;
  title: string;
  author: string;
  isbn: string,
  publication_date: string,
  genre: string,
  description: string,
  units: number,
  units_left: number,
}

const columns: Column<Book>[] = [
  { heading: 'Title', type: 'data', path: 'title' },
  { heading: 'Author', type: 'data', path: 'author' },
  { heading: 'ISBN', type: 'data', path: 'isbn' },
  { heading: 'Publication Date', type: 'data', path: 'publication_date' },
  { heading: 'Genre', type: 'data', path: 'genre' },
  { heading: 'Total Units', type: 'data', path: 'units' },
  { heading: 'Untracked Units', type: 'data', path: 'units_left' },
];

function Books() {
  const { books } = useLoaderData() as any;
  const navigate = useNavigate();

  const [isSavingBooks, setIsSavingBooks] = useState(false);
  const [addedBooks, setAddedBooks] = useState<any[]>([]);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastBg, setToastBg] = useState('dark');

  const fileInputRef = useRef<HTMLInputElement>();

  const handleAddBookClick = () => navigate('/books/add');

  const columnsWithActions: Column<Book>[] = useMemo(() => {
    const actions: ColumnAction<Book>[] = [
      {
        text: 'Edit',
        onClick(item, event) {
          navigate(`/books/edit/${item.id}`);
        }
      },
      {
        text: 'Delete',
        className: 'text-danger',
        onClick(item, event) {
          navigate(`/books/delete/${item.id}`);
        },
      },
      {
        text: 'Assign',
        onClick(item, event) {
          navigate(`/books/assign/${item.id}`);
        },
        showAction(item) {
          return item.units_left > 0;
        },
      }
    ];

    return [...columns.slice(), { heading: 'Actions', type: 'actions', actions }];
  }, [navigate]);

  const handleFileChange = (event: SyntheticEvent<HTMLInputElement>) => {
    const file = get(event, 'currentTarget.files[0]');
    if (file) {
      setIsSavingBooks(true);

      Papa.parse<any>(file, {
        header: true,
        skipEmptyLines: true,
        complete: async function (results) {
          try {
            if (!isEmpty(results.errors)) throw results.errors;

            const booksPayload = results.data.map((row) => {
              const mappedRow: Record<string, any> = {};

              const keys = Object.keys(row);
              for (const key of keys) {
                const lowerCasedKey = key.toLowerCase();

                switch (lowerCasedKey) {
                  case 'title':
                  case 'author': {
                    if (isEmpty(row[key])) throw new Error(`${capitalize(lowerCasedKey)} is required`);
                    break;
                  }

                  case 'units': {
                    if (!isNumeric(row[key]) || Number(row[key]) <= 0) throw new Error('Units should be a number greater than 0');
                    else row[key] = Number(row[key]);
                    break;
                  }

                  default: {
                    if (!['isbn', 'genre', 'description', 'publication_date'].includes(lowerCasedKey))
                      throw new Error('Unknown key/heading');
                    break;
                  }
                }

                mappedRow[lowerCasedKey] = row[key];
              }

              return mappedRow;
            });

            const newBooks: any[] = await addBooks({ books: booksPayload });
            setAddedBooks(currentBooks => [...currentBooks, ...newBooks]);
          } catch (errors) {
            setToastMessage(String(errors));
            setToastBg('danger');
            setShowToast(true);
            console.error(errors);
          } finally {
            setIsSavingBooks(false);
            fileInputRef.current!.value = '';
          }
        },
      });
    }
  }

  const handleUploadButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleDownloadButtonClick = () => {
    const link = document.createElement('a');
    link.download = 'books.csv';
    link.target = '_blank';
    link.href = './csv/books.csv';
    link.click();
  };

  return (
    <>
      <PageHeading heading='Books' actionButton={{ text: 'Add New Book', onClick: handleAddBookClick }} />
      <DataTable<Book> rows={[...(books || []), ...addedBooks]} columns={columnsWithActions} />

      <StyledButton
        disabled={isSavingBooks}
        onClick={!isSavingBooks ? handleUploadButtonClick : undefined}
      >
        {
          isSavingBooks
          ? (
            <>
              <Spinner animation='border' role='status'>
                <span className='visually-hidden'>Loading...</span>
              </Spinner>&nbsp; Saving...
            </>
          )
          : (
            <><UploadIcon />&nbsp; Load Books</>
          )
        }
      </StyledButton>
      <StyledInput ref={fileInputRef as any} type='file' accept='.csv' onChange={handleFileChange} />
      <StyledButton onClick={handleDownloadButtonClick}><DownloadIcon />&nbsp; Download CSV Template</StyledButton>

      <ToastContainer position='bottom-end'>
        <Toast
          style={{ color: 'white' }}
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={10000}
          bg={toastBg}
          autohide
        >
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
      </ToastContainer>

      <Outlet />
    </>
  );
}

export default withAuthenticationRequired(Books);

const StyledButton = styled(Button)`
  background-color: #3498db;
  border-color: #3498db;
  color: #fff;
  margin-right: 10px;
`;

const StyledInput = styled.input`
  display: none;
`;
